import { required } from 'helpers/validators';
import { Field, reduxForm } from 'redux-form';

import React, { Fragment, useCallback, useEffect } from 'react';

import { t } from '@lingui/macro';

import Button from 'components/Button';
import Input from 'components/Input';
import RenderTextAreaField from 'components/RenderTextAreaField';

export const BlockForm = ({
  block,
  change,
  handleSubmit,
  onSubmit,
  invalid,
  submitting,
}) => {
  const changeField = useCallback((key, value) => change(key, value), []);

  useEffect(() => {
    changeField('key', block?.key);
    changeField('description', block?.description);
    changeField('body', block?.body);
  }, [block, changeField]);

  return (
    <Fragment>
      <form className='blockForm' onSubmit={onSubmit}>
        <Field
          name='key'
          component={Input}
          placeholder={t({ id: 'block.editor.key.title', message: `Key` })}
          validate={required()}
          testSelector='campaign-key'
        />

        <Field
          name='description'
          component={Input}
          placeholder={t({
            id: 'block.editor.desription.title',
            message: `Description`,
          })}
          validate={required()}
          testSelector='campaign-description'
        />

        <Field
          className='blockForm--textArea'
          validate={required()}
          name='body'
          fieldName={t({ id: 'template.editor.body.title', message: `Body` })}
          component={RenderTextAreaField}
          testSelector='campaign-body'
        />

        <div className='blockForm--actionButtons'>
          <Button
            onClick={handleSubmit}
            disabled={invalid || submitting}
            testSelector='block-form'
          >
            Save
          </Button>
        </div>
      </form>
    </Fragment>
  );
};

export default reduxForm({
  form: 'block-editor',
  enableReinitialize: true,
})(BlockForm);
